import React from 'react'

import data from '../../json/ourService.json'
import Banner from '../Banner'
const OurServices = () => {
  return (
    <>
      <div

      >
        <div className='w-[100%] max-w-[1440px] mx-auto px-[3%] pt-[60px] sm:pt-[90px] md:pt-[120px]'
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className='w-[100%] flex flex-col items-center'>
            <h1 className='text-center font-[500] text-[30px] sm:text-[48px] fredoka text-white'>Our Services</h1>
            <h3 className='mt-[20px] max-w-[840px] text-center text-[18px] sm:text-[24px] nunito text-white'>{data?.description}
            </h3>
          </div>
        </div>
        <Banner />
        <div className='w-[100%] max-w-[1440px] mx-auto px-[3%] pt-[30px] sm:pt-[60px] md:pt-[90px]'>
          <div className='grid grid-cols-1 sm:grid-cols-2 sm:mt-[60px]'>
            {data?.services.map((obj) => {
              return (
                <div className='flex flex-col items-center sm:mt-[0px] mt-[40px]'
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <img src={obj?.img} alt="" className='h-[170px] sm:h-[250px] object-contain ' />
                  <p className='text-[24px] font-[500] mt-[10px] fredoka '>{obj?.service}</p>
                  <p className='max-w-[450px] text-center text-[14px] sm:text-[18px] nunito  '>{obj?.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default OurServices
